@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 90%;
    max-width: 600px;
    padding: 5px;
}

.popup-arrow {
    color: rgb(255, 255, 255);
}

[role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
    background: transparent;
}

.react-calendar-heatmap .color-empty {
    fill: #fff5ba !important;
}

.react-calendar-heatmap .color-scale-1 {
    fill: #ffc400
}

.react-calendar-heatmap-month-label {
    fill: black !important;
}

.react-calendar-heatmap-weekday-label {
    fill: black !important;
}

.react-calendar-heatmap rect {
    border-radius: 50%;

}

.fuchi {
    font-size: xx-large;
    font-weight: bold;
    text-shadow: -1px -1px 0 black, -1px 0 0 black, -1px 1px 0 black,
    0 -1px 0 black, 0 1px 0 black,
    1px -1px 0 black, 1px 0 0 black, 1px 1px 0 black;
}